import { createSlice } from '@reduxjs/toolkit';
import type { clientData } from 'models/user';

const initialState: clientData = {
  error_message: '',
  loader: false,
};

const messageSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.error_message = action.payload;
    },
    clearMessage: (state) => {
      state.error_message = '';
    },
    setLoader: (state, action) => {
      state.loader = true;
    },
    unsetLoader: (state) => {
      state.loader = false;
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage, setLoader, unsetLoader } = actions;
export default reducer;
