export function romanizeGreek(text: string): string {
  const greekToRomanMap: { [key: string]: string } = {
    α: 'a',
    β: 'v',
    γ: 'g',
    δ: 'd',
    ε: 'e',
    ζ: 'z',
    η: 'i',
    θ: 'th',
    ι: 'i',
    κ: 'k',
    λ: 'l',
    μ: 'm',
    ν: 'n',
    ξ: 'x',
    ο: 'o',
    π: 'p',
    ρ: 'r',
    σ: 's',
    τ: 't',
    υ: 'y',
    φ: 'f',
    χ: 'ch',
    ψ: 'ps',
    ω: 'o',
    ά: 'a',
    έ: 'e',
    ή: 'i',
    ί: 'i',
    ό: 'o',
    ύ: 'y',
    ώ: 'o',
    ς: 's',
    ϊ: 'i',
    ΐ: 'i',
    ϋ: 'y',
    ΰ: 'y',
  };

  let romanizedText = '';
  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i).toLowerCase();
    if (greekToRomanMap.hasOwnProperty(char)) {
      romanizedText += greekToRomanMap[char];
    } else {
      romanizedText += char;
    }
  }

  return romanizedText;
}

export function includesIgnoreCase(str: string, substr: string): boolean {
  return str.toLowerCase().includes(substr.toLowerCase());
}

export const commaSeparatedFormatter = (amount: number) => {
  const value = Number(amount);
  if (isNaN(value)) {
    return '0.00';
  }
  return value.toLocaleString('el-GR');
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function clearLocalStorageExcept(keysToKeep: string[]) {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!keysToKeep.includes(key!)) {
      localStorage.removeItem(key!);
    }
  }
}
