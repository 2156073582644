import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Prospect, ProspectAdd, ProspectEdit, ProspectId } from 'models/prospect';
import { clearMessage, setLoader, setMessage, unsetLoader } from './reducerClient';
import apiClient from 'api-client';

export const getProspects = createAsyncThunk<Prospect[], void, { rejectValue: string }>(
  'prospects/getProspects',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoader(''));
    try {
      const response = await apiClient.get('/prospects');
      return response.data as Prospect[];
    } catch (error) {
      thunkAPI.dispatch(setMessage('error getting prospects'));
      setTimeout(() => {
        thunkAPI.dispatch(clearMessage());
      }, 2500);
      return thunkAPI.rejectWithValue('error getting prospects');
    } finally {
      thunkAPI.dispatch(unsetLoader());
    }
  },
);

export const addProspect = createAsyncThunk('prospects/addProspect', async (data: ProspectAdd, thunkAPI) => {
  try {
    const response = await apiClient.post('/add_prospect', data);
    if (response) {
      const prospect: Prospect = response.data.result;
      return prospect;
    }
  } catch (error) {
    console.log(error);
    // @ts-ignore
    thunkAPI.dispatch(setMessage('failed to add'));
    return thunkAPI.rejectWithValue('failed to add');
  }
});

export const editProspect = createAsyncThunk(
  'prospects/editProspect',
  async (data: ProspectEdit, thunkAPI) => {
    try {
      const response = await apiClient.put('/update_prospect', data);
      if (response) {
        return response.data.result;
      }
    } catch (error) {
      console.log(error);
      // @ts-ignore
      thunkAPI.dispatch(setMessage('failed to edit'));
      return thunkAPI.rejectWithValue('failed to edit');
    }
  },
);

export const deleteProspect = createAsyncThunk(
  'prospects/deleteProspect',
  async (id: ProspectId, thunkAPI) => {
    try {
      const response = await apiClient.delete(`/delete_prospect/${id}`);
      if (response) {
        return id;
      }
    } catch (error) {
      console.log(error);
      // @ts-ignore
      thunkAPI.dispatch(setMessage('failed to delete'));
      return thunkAPI.rejectWithValue('failed to delete');
    }
  },
);

const initialState: Prospect[] = [];

const prospectSlice = createSlice({
  name: 'prospects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProspects.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(addProspect.fulfilled, (state, action) => {
      const newProspect = action.payload;
      if (newProspect) {
        state.push(newProspect);
      }
    });
    builder.addCase(editProspect.fulfilled, (state, action) => {
      const editedProspect = action.payload;
      if (editedProspect) {
        const index = state.findIndex((prospect) => prospect.id === editedProspect.id);
        if (index !== -1) {
          state[index] = editedProspect;
        }
      }
    });
    builder.addCase(deleteProspect.fulfilled, (state, action) => {
      const id = action.payload;
      const index = state.findIndex((prospect) => Number(prospect.id) === Number(id));
      if (index !== -1) {
        state.splice(index, 1);
      }
    });
  },
});

const { reducer } = prospectSlice;

export default reducer;
