import React, { MouseEventHandler } from 'react';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';

interface Props {
  HandleLogout: MouseEventHandler;
}

export default function LogOutBtn({ HandleLogout }: Props) {
  return (
    <Button sx={{ ml: 2 }} variant={'outlined'} onClick={HandleLogout}>
      logout
      <PersonIcon />
    </Button>
  );
}
