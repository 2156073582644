import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const useAuth = (): boolean => {
  const { token } = useSelector((state: RootState) => state.authUser);

  const [authState, setAuthState] = useState<boolean>(!!token);

  useEffect(() => {
    setAuthState(!!token);
  }, [token]);

  return authState;
};

export default useAuth;
