import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { logout } from 'redux/reducerAuth';
import useAuth from 'hooks/useAuthentication';
import { ReactNode } from 'react';
import UpHellasLogoMD from 'assets/UpHellasLogoMD.png';
import NavItem, { NavBar } from 'components/NavLink/NavLink';
import LogOutBtn from 'components/NavLink/LogOutBtn';
import { Typography, Box, useMediaQuery, Theme } from '@mui/material';
import ErrorSnackbar from 'components/messages/LayoutError';

type ProtectedRouteProps = {
  children?: ReactNode;
};

export const ProtectedLayout = ({ children }: ProtectedRouteProps) => {
  const authenticated = useAuth();
  const dispatch = useDispatch<AppDispatch>();

  const { error_message } = useSelector((state: RootState) => state.client);
  const { user } = useSelector((state: RootState) => state.authUser);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (!authenticated) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  const logOutHandler = () => {
    dispatch(logout());
  };

  return (
    <>
      <NavBar
        sx={{
          background: (theme) => theme.palette.background.paper,
          boxShadow: 1,
        }}
      >
        <Box display={'flex'} alignItems={'center'}>
          <img
            src={UpHellasLogoMD}
            alt={'Up Hellas Logo'}
            style={{ paddingTop: 5, paddingBottom: 5, borderRadius: 13 }}
          />
          {!isSmallScreen && (
            <Box display={'flex'} alignItems={'start'} flexDirection={'column'} marginLeft={1}>
              <Typography variant={'caption'} fontWeight={'bold'}>
                {user?.department}:
              </Typography>
              <Typography
                variant={'body2'}
                sx={{
                  borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`,
                }}
              >
                {user?.fullname}
              </Typography>
            </Box>
          )}
        </Box>

        <div>
          <NavItem to={'reports'} />
          <NavItem to={'prospects'} />
          <NavItem to={'forecasts'} />
          <LogOutBtn HandleLogout={logOutHandler} />
        </div>
      </NavBar>
      {children}
      <Outlet />
      <ErrorSnackbar errorMessage={error_message} />
    </>
  );
};
