import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage, setLoader, unsetLoader, clearMessage } from './reducerClient';
import { emptyForecasts } from './reducerForecast';
import apiClient from 'api-client';
import { AuthState, LoginData } from 'models/user';
import { clearLocalStorageExcept } from '../utils/string';

export const login = createAsyncThunk('auth/login', async (data: LoginData, thunkAPI) => {
  thunkAPI.dispatch(setLoader(''));
  try {
    const response_auth = await apiClient.post('/login', data);
    localStorage.setItem('authStore', JSON.stringify(response_auth.data));
    return response_auth.data;
  } catch (error) {
    thunkAPI.dispatch(setMessage('Credentials dont match'));
    setTimeout(() => {
      thunkAPI.dispatch(clearMessage());
    }, 2500);
    return thunkAPI.rejectWithValue('user and password dont match');
  } finally {
    thunkAPI.dispatch(emptyForecasts());
    thunkAPI.dispatch(unsetLoader());
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  thunkAPI.dispatch(setLoader(''));
  try {
    await apiClient.delete('/logout');
    return initialAuth;
  } catch (error) {
    thunkAPI.dispatch(setMessage('error logging out'));
    setTimeout(() => {
      thunkAPI.dispatch(clearMessage());
    }, 2500);
    return thunkAPI.rejectWithValue('failed to log out');
  } finally {
    clearLocalStorageExcept(['forecast_colum_model']);
    thunkAPI.dispatch(unsetLoader());
  }
});

const initialAuth: AuthState = {
  token: '',
  user: {
    fullname: '',
    email: '',
    department: '',
    role: {},
  },
};

const currentState = localStorage.getItem('authStore');
const initialState: AuthState = currentState ? JSON.parse(currentState) : initialAuth;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      return initialAuth;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      clearLocalStorageExcept(['forecast_colum_model']);
      return action.payload;
    });
  },
});

const { reducer } = authSlice;
export default reducer;
