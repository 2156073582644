import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { ProtectedLayout } from './ProtectedLayout';
const Login = lazy(() => import('screens/Login'));
const Forecasts = lazy(() => import('screens/Forecasts/Forecasts'));
const Prospects = lazy(() => import('screens/Prospects/Prospects'));
const Reports = lazy(() => import('screens/Reports'));
const NotFound = lazy(() => import('screens/NotFound'));

const App = () => {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          <Route path="/*" element={<Login />} />
          <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="forecasts" element={<Forecasts />} />
            <Route path="prospects" element={<Prospects />} />
            <Route path="reports" element={<Reports />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
