import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { clearMessage } from 'redux/reducerClient';

interface Props {
  errorMessage: string;
}

const ErrorSnackbar: React.FC<Props> = ({ errorMessage }) => {
  const dispatch = useDispatch();

  const open = errorMessage.length > 0;

  const handleClose = useCallback(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      if (errorMessage.length) dispatch(clearMessage());
    }, 4000);

    return () => {
      if (errorMessage.length) clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <Snackbar
      open={open}
      disableWindowBlurListener={true}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
