import React from 'react';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const NavBar = styled('div')(({ theme }) => ({
  boxShadow: `0 0 0 1px ${theme.palette.secondary.light} inset`,
  color: `${theme.palette.text.primary}`,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: 20,
  paddingRight: 20,
}));

export const NavItemLink = styled(NavLink)(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  textDecoration: 'none',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  background: 'transparent',
  fontSize: 15,
  '&:hover': {
    textDecoration: 'underline',
    background: 'transparent',
  },

  '&.active': {
    textDecoration: 'none',
    fontWeight: 'bold',
    background: 'transparent',
  },
}));

interface Props {
  to: string;
}

export default function NavItem({ to }: Props) {
  return (
    <NavItemLink to={to} sx={{ ml: 2 }}>
      {to}
    </NavItemLink>
  );
}
