import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Forecast,
  ForcastUpdatePayload,
  companyActivityUpdatePayload,
  companyAnnualBudgetUpdatePayload,
} from 'models/forecast';
import { clearMessage, setLoader, setMessage, unsetLoader } from './reducerClient';
import apiClient from 'api-client';

export const getForecast = createAsyncThunk('forecast/getForecast', async (_, thunkAPI) => {
  thunkAPI.dispatch(setLoader(''));
  try {
    const response_forecast = await apiClient.get('/get_companies_by_user');
    return response_forecast.data;
  } catch (error) {
    thunkAPI.dispatch(setMessage('error getting forecasts'));
    setTimeout(() => {
      thunkAPI.dispatch(clearMessage());
    }, 2500);
    return thunkAPI.rejectWithValue('error getting forecasts');
  } finally {
    thunkAPI.dispatch(unsetLoader());
  }
});

export const editForecast = createAsyncThunk(
  'forecast/editForecast',
  async (data: ForcastUpdatePayload, thunkAPI) => {
    try {
      const response_forecast = await apiClient.put('/change_forecast', data);
      if (response_forecast) {
        //thunkAPI.dispatch(getForecast());
        return data;
      }
    } catch (error) {
      console.log(error);
      // @ts-ignore
      thunkAPI.dispatch(setMessage(error?.response?.data?.msg));
      return thunkAPI.rejectWithValue('failed to edit');
    }
  },
);

export const updateCompany = createAsyncThunk(
  'forecast/changeActivity',
  async (data: companyActivityUpdatePayload, thunkAPI) => {
    try {
      const response = await apiClient.put('/change_activity', data);
      if (response) {
        thunkAPI.dispatch(getForecast());
      }
    } catch (error) {
      console.log(error);
      // @ts-ignore
      thunkAPI.dispatch(setMessage(error?.response?.data?.msg));
      return thunkAPI.rejectWithValue('failed to update company activity');
    }
  },
);

export const updateCompanyAnnualBudget = createAsyncThunk(
  'forecast/updateCompanyAnnualBudget',
  async (payload: companyAnnualBudgetUpdatePayload, thunkAPI) => {
    try {
      const { data } = await apiClient.put('/change_annual_budget', payload);
      console.log(data);
      return payload;
    } catch (error) {
      console.log(error);
      // @ts-ignore
      thunkAPI.dispatch(setMessage(error?.response?.data?.msg));
      return thunkAPI.rejectWithValue('failed to update company annual budget');
    }
  },
);

const initialState: Forecast[] = [];

const forecastSlice = createSlice({
  name: 'forecast',
  initialState,
  reducers: {
    emptyForecasts: () => {
      return [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getForecast.fulfilled, (state, action) => {
      return action.payload;
    });
    // @ts-ignore
    builder.addCase(editForecast.fulfilled, (state, action) => {
      const customer_id = action.payload?.customer_id;
      let month = action.payload?.month;
      const forecast = action.payload?.forecast;
      if (typeof month !== 'undefined') {
        const monthString = getMonthName(month);

        return state.map((item) => {
          if (item.customer_id === customer_id && monthString) {
            return {
              ...item,
              monthly_forecast: {
                ...item.monthly_forecast,
                [monthString]: {
                  ...item.monthly_forecast[monthString],
                  forecast: forecast,
                },
              },
            };
          }
          return item;
        });
      }
    });
    builder.addCase(updateCompanyAnnualBudget.fulfilled, (state, action) => {
      const { customer_id, annual_budget } = action.payload;
      return state.map((item) => {
        if (item.customer_id === customer_id) {
          return { ...item, annual_budget: annual_budget };
        }
        return item;
      });
    });
  },
});

function getMonthName(monthNumber: number): string {
  const monthName = new Date(2023, monthNumber - 1).toLocaleString('en', {
    month: 'long',
  });
  return monthName;
}

const { reducer, actions } = forecastSlice;

export const { emptyForecasts } = actions;
export default reducer;
