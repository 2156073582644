import axios from 'axios';
import { store } from 'redux/store';
import { clearLocalStorageExcept } from 'utils/string';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
// Add an interceptor that adds the token to the headers of all requests
apiClient.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state?.authUser?.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiClient.interceptors.response.use(undefined, (err) => {
  //alert(err.response.data.message);
  const error = err.response;
  // if error is 401 it means either token is wrong or has expired
  if (error.status === 401) {
    clearLocalStorageExcept(['forecast_colum_model']);
    // @ts-ignore
    window.location = window.location.origin;
  }
  return Promise.reject(err);
});

export default apiClient;
