import { configureStore } from '@reduxjs/toolkit';
import reducerAuth from './reducerAuth';
import reducerClient from './reducerClient';
import reducerForecast from './reducerForecast';
import reducerProspects from './reducerProspects';

const reducer = {
  authUser: reducerAuth,
  client: reducerClient,
  forecast: reducerForecast,
  prospects: reducerProspects,
};
export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV === 'development',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
