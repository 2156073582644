import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#ffad40',
      main: '#ff9900',
      dark: '#ff8827',
    },
    secondary: {
      light: '#f9eee5',
      main: '#fdd9b3',
      dark: '#ffc28a',
    },
    error: {
      light: '#f72b2b',
      main: '#c50e1f',
      dark: '#a80f22',
    },
    warning: {
      light: '#fcd37a',
      main: '#ffcc66',
      dark: '#ffbc27',
    },
    info: {
      light: '#70e0f9',
      main: '#33ccff',
      dark: '#0066cc',
    },
    success: {
      light: '#4caf50',
      main: '#2e7d32',
      dark: '#1b5e20',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    text: {
      primary: '#333', // Dark gray
      secondary: '#666', // Gray
      disabled: '#999', // Light gray
    },
  },
  typography: {
    fontFamily: 'FontUp',
  },
  shape: {
    borderRadius: 4, // or any other value you prefer
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 760,
      lg: 1050,
      xl: 1400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b #f1f1f1',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#6b6b6b',
            minHeight: 24,
            border: '3px solid #f1f1f1',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#f1f1f1',
          },
        },
      },
    },
  },
});
